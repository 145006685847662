import React, { ReactElement } from 'react';
import { SleepCategory, SleepCategoryMap } from 'pages/Dashboard/types/waypoints.types';
import { Box, Button, FormControl, Grid, Icon, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import Theme from 'theme';
import { format } from 'date-fns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export function convertDecimalToHoursMinutes(
  decimalNumber: number,
  key?: string,
  fontWeight = '400',
  keepActualColor = false,
): React.ReactElement | null {
  const number = typeof decimalNumber === 'string' ? parseFloat(decimalNumber) : decimalNumber;
  const hours = Math.floor(number);
  const minutes = Math.round((number - hours) * 60);
  const values = [];
  if (hours >= 1) {
    values.push({ key: 'h', value: minutes === 60 ? hours + 1 : hours });
  }
  if (minutes >= 1 && minutes < 60) {
    values.push({ key: 'min', value: minutes });
  }

  if (key === 'awake_time_sleep_occurence') {
    return (
      <Box display='flex' alignItems='baseline'>
        <Typography
          variant='body1'
          lineHeight='100%'
          fontWeight={fontWeight}
          fontSize={fontWeight === '700' ? 20 : 16}
        >
          {decimalNumber.toFixed(0)}
        </Typography>
        {fontWeight !== '400' && (
          <Typography
            variant={fontWeight === '700' ? 'body1' : 'body2'}
            lineHeight='100%'
            ml={0.5}
            {...(!keepActualColor ? { color: Theme.custom.colors.lightTextSecondary } : {})}
          >
            per night
          </Typography>
        )}
      </Box>
    );
  }

  if (key?.includes('sleep_avg')) {
    return (
      <Box display='flex' alignItems='baseline'>
        <Typography variant='body1' lineHeight='100%'>
          {format(decimalNumber * 1000, 'h:mm a')}
        </Typography>
      </Box>
    );
  }

  if (values.length === 0) {
    return null;
  }

  return (
    <Box display='flex'>
      {values.map((avg, index) => (
        <Typography
          key={avg.key}
          marginRight={index === 0 ? 1 : 0}
          display='flex'
          alignItems='baseline'
        >
          <Typography
            variant='body1'
            lineHeight='100%'
            fontWeight={fontWeight}
            fontSize={fontWeight === '700' ? 20 : 16}
          >
            {avg.value}
          </Typography>
          <Typography
            variant={fontWeight === '700' ? 'body1' : 'body2'}
            lineHeight='100%'
            {...(!keepActualColor ? { color: Theme.custom.colors.lightTextSecondary } : {})}
          >
            {avg.key}
          </Typography>
        </Typography>
      ))}
    </Box>
  );
}

interface AppleHealthSleepChartProps {
  timeFrame: string;
  averages: { id: SleepCategory; absoluteValue: number }[];
  previousPeriodAverages: { id: SleepCategory; absoluteValue: number }[];
  sourceId: string;
  sourceOptions: { label: string; value: string, isSelected: boolean }[];
  setSourceId: (sourceId: string) => void;
  openDrawer: () => void;
}

function AppleHealthSleepChart({
  timeFrame,
  averages,
  previousPeriodAverages,
  sourceId,
  sourceOptions,
  setSourceId,
  openDrawer,
}: AppleHealthSleepChartProps) {
  const previousPeriodAverageMap = previousPeriodAverages.reduce((a, avg) => {
    if (avg?.id) {
      return {
        ...a,
        [avg.id]: { component: convertDecimalToHoursMinutes(avg.absoluteValue, avg.id, '400.0', false), value: avg.absoluteValue },
      };
    }
    return a;
  }, {} as Record<SleepCategory, { component: ReactElement, value : number}>);

  const averageMap = averages.reduce((a, avg) => {
    if (avg?.id) {
      const showingOccurences = avg.id.includes('sleep_occurence');
      const currentValue = showingOccurences ? avg.absoluteValue.toFixed(0) : avg.absoluteValue;
      const previousValue = showingOccurences
        ? previousPeriodAverageMap[avg.id]?.value.toFixed(0)
        : previousPeriodAverageMap[avg.id]?.value;
      const isUp = currentValue
      && previousValue
      && currentValue !== previousValue
        ? avg.absoluteValue > previousPeriodAverageMap[avg.id]?.value
        : undefined;
      return {
        ...a,
        [avg.id]: {
          component: (fontWeight?: string) => convertDecimalToHoursMinutes(
            avg.absoluteValue,
            avg.id,
            fontWeight,
          ),
          up: isUp,
        },
      };
    }
    return a;
  }, {} as Record<SleepCategory, {
     component: (fontWeight?: string) => ReactElement;
     up: true | false | undefined
  }>);

  const averageCategories = Object.keys(SleepCategoryMap).reduce((a, key) => {
    if (!SleepCategoryMap[key as SleepCategory].isAsleepCategory) {
      a.splice(SleepCategoryMap[key as SleepCategory].index, 0, key);
    }
    return a;
  }, [] as string[]);


  const priorAvgText = React.useMemo(() => `prior ${timeFrame.replace(' ', '-').replace('s', '')} avg.`, [timeFrame]);

  const avgValues = React.useMemo(() => averageCategories.map((actualKey, index) => {
    const key = (actualKey as SleepCategory) === SleepCategory.AWAKE_TIME
      ? 'awake_time_sleep_occurence'
      : (actualKey as SleepCategory);
    const currentValue = averageMap?.[key as SleepCategory]?.component?.('700');
    const previousValue = previousPeriodAverageMap?.[key as SleepCategory]?.component;
    const currentColor = currentValue ? '' : Theme.custom.colors.lightTextSecondary;
    const ArrowIcon = averageMap?.[key as SleepCategory]?.up
      ? ArrowDropUpIcon
      : ArrowDropDownIcon;

    return (
      <Grid item key={key} mb={2}>
        {/* TODO: Update color here */}
        <Box borderLeft={index > 0 ? '1px solid #0000001F' : ''} pl={index > 0 ? 1.5 : 0} pr={index < averageCategories.length ? 1 : 0}>
          <Typography
            variant='body1'
            color={
            SleepCategoryMap[actualKey as SleepCategory].averageLabelColor
            || SleepCategoryMap[actualKey as SleepCategory].color
          }
            fontWeight='700'
          >
            {SleepCategoryMap[actualKey as SleepCategory].averageLabel}
          </Typography>
          <Typography color={currentColor} mb={2} lineHeight='100%' display='flex' alignItems='center' height={20}>
            {currentValue ?? '-'}
            {averageMap?.[key as SleepCategory]?.up !== undefined && <ArrowIcon color='action' />}
          </Typography>
          <Typography fontSize={12} color={Theme.custom.colors.lightTextSecondary} mb={0.5}>
            {priorAvgText}
          </Typography>
          <Typography lineHeight='100%'>
            {previousValue ?? '-'}
          </Typography>
        </Box>
      </Grid>
    );
  }), [averageCategories, averageMap, previousPeriodAverageMap, priorAvgText]);

  const handleChange = (event: SelectChangeEvent) => {
    setSourceId(event.target.value as string);
  };


  return (
    <>
      {sourceOptions.length > 1 && (
      <FormControl sx={{ mb: 3 }}>
        <InputLabel>Data source</InputLabel>
        <Select value={sourceId} label='Data source' onChange={handleChange}>
          {sourceOptions.map((s) => (
            <MenuItem key={s.value} value={s.value}>
              {s.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      )}
      {sourceOptions.length === 1 && (
      <Typography color={Theme.custom.colors.lightTextSecondary} sx={{ mb: 3 }}>
        Source:
        {' '}
        {sourceOptions[0].label}
      </Typography>
      )}
      {
        averages.length > 0 ? (
          <Box>
            <Grid container>
              {avgValues}
            </Grid>
            <Box alignItems='center' justifyContent='center' display='flex' flex={1} marginY={2}>
              <Button
                color='primary'
                variant='outlined'
                size='large'
                startIcon={<Icon>bar_chart</Icon>}
                onClick={openDrawer}
              >
                VIEW DETAILS
              </Button>
            </Box>
          </Box>
        )
          : (
            <Box textAlign='center' paddingY={12}>
              <Typography>no data exists</Typography>
            </Box>
          )
      }

    </>
  );
}

export default AppleHealthSleepChart;
